.react-contexify {
  position: fixed;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 10px 20px, #eeeeee 0 0 0 1px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 10px 20px, #eeeeee 0 0 0 1px;
  padding: 5px 0;
  min-width: 180px;
}
.react-contexify .react-contexify__submenu {
  position: absolute;
  top: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.275s;
  transition: opacity 0.275s;
}
.react-contexify__submenu-arrow {
  font-size: 12px;
  position: absolute;
  right: 10px;
  line-height: 22px;
}
.react-contexify__separator {
  width: 100%;
  height: 1px;
  cursor: default;
  margin: 4px 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.react-contexify__item {
  cursor: pointer;
  position: relative;
}
/* .react-contexify__item:not(.react-contexify__item--disabled):hover
  > .react-contexify__item__content {
  color: white;
  background-color: #4393e6;
} */
.react-contexify__item:not(.react-contexify__item--disabled):hover
  > .react-contexify__submenu {
  pointer-events: initial;
  opacity: 1;
}
.react-contexify__item--disabled {
  cursor: default;
  opacity: 0.5;
}
.react-contexify__item__content {
  padding: 6px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  white-space: nowrap;
  /* color: #333; */
  position: relative;
}
.react-contexify__item__icon {
  font-size: 20px;
  margin-right: 5px;
  font-style: normal;
}

.react-contexify__theme--dark {
  padding: 6px 0;
  background-color: rgba(40, 40, 40, 0.98);
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4), 0 0 0 1px #222;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4), 0 0 0 1px #222;
}
.react-contexify__theme--dark .react-contexify__submenu {
  background-color: rgba(40, 40, 40, 0.98);
}
.react-contexify__theme--dark .react-contexify__separator {
  background-color: #eee;
}
.react-contexify__theme--dark .react-contexify__item__content {
  color: #ffffff;
}
.react-contexify__theme--dark .react-contexify__item__icon {
  margin-right: 8px;
  width: 12px;
  text-align: center;
}

.react-contexify__theme--light {
  padding: 6px 0;
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2), 0 0 0 1px #eee;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2), 0 0 0 1px #eee;
}
.react-contexify__theme--light .react-contexify__separator {
  background-color: #eee;
}
/* .react-contexify__theme--light
  .react-contexify__item:not(.react-contexify__item--disabled):hover
  > .react-contexify__item__content {
  color: #4393e6;
  background-color: #e0eefd;
} */
.react-contexify__theme--light .react-contexify__item__content {
  color: #666;
}
.react-contexify__theme--light .react-contexify__item__icon {
  margin-right: 8px;
  width: 12px;
  text-align: center;
}

@-webkit-keyframes react-contexify__popIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes react-contexify__popIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes react-contexify__popOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes react-contexify__popOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

.react-contexify__will-enter--pop {
  -webkit-animation: react-contexify__popIn 0.3s
    cubic-bezier(0.51, 0.92, 0.24, 1.2);
  animation: react-contexify__popIn 0.3s cubic-bezier(0.51, 0.92, 0.24, 1.2);
}

.react-contexify__will-leave--pop {
  -webkit-animation: react-contexify__popOut 0.3s
    cubic-bezier(0.51, 0.92, 0.24, 1.2);
  animation: react-contexify__popOut 0.3s cubic-bezier(0.51, 0.92, 0.24, 1.2);
}

@-webkit-keyframes react-contexify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes react-contexify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes react-contexify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

@keyframes react-contexify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

.react-contexify__will-enter--zoom {
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-animation: react-contexify__zoomIn 0.4s;
  animation: react-contexify__zoomIn 0.4s;
}

.react-contexify__will-leave--zoom {
  -webkit-animation: react-contexify__zoomOut 0.4s;
  animation: react-contexify__zoomOut 0.4s;
}

@-webkit-keyframes react-contexify__fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-contexify__fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes react-contexify__fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes react-contexify__fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.react-contexify__will-enter--fade {
  -webkit-animation: react-contexify__fadeIn 0.3s ease;
  animation: react-contexify__fadeIn 0.3s ease;
}

.react-contexify__will-leave--fade {
  -webkit-animation: react-contexify__fadeOut 0.3s ease;
  animation: react-contexify__fadeOut 0.3s ease;
}

@-webkit-keyframes react-contexify__flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes react-contexify__flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@-webkit-keyframes react-contexify__flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

@keyframes react-contexify__flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.react-contexify__will-enter--flip {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation: react-contexify__flipInX 0.65s;
  animation: react-contexify__flipInX 0.65s;
}

.react-contexify__will-leave--flip {
  -webkit-animation: react-contexify__flipOutX 0.65s;
  animation: react-contexify__flipOutX 0.65s;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

/*# sourceMappingURL=ReactContexify.css.map */
